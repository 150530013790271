import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GeneralSettingValue, GeneralSettingValueService, SocietiesService, Society, User } from '@pwc-ecobonus/common';
import { AuthenticationService, Authority, AuthorizationService, IdleTimeoutService } from '@pwc-ecobonus/security';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { first, switchMap } from 'rxjs/operators';
import { Logger } from '@nsalaun/ng-logger';
import { LogoKey } from '../../../enums/logo-key.enum';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { RoutePath } from 'src/app/enums/route-path.enum';
import { AuthorityCodeEnum } from 'src/app/enums/security/authority-code.enum';
import { CondominiumType } from 'src/app/models/condominium-type';
import { Project } from 'src/app/pages/project/models/project';
import { BasePage } from 'src/app/pages/base-page/base-page';
import { CodeNameEntity } from 'src/app/models/api/code-name-entity';
import { RealEstateUnitCondominium } from 'src/app/models/api/real-estate-unit-condominium';
import { CondominiumManager } from 'src/app/models/api/condominium-manager';
import { Owner } from 'src/app/models/registry/owner';
import {
  RealEstateUnitSelectionModalComponent
} from 'src/app/pages/registry/real-estate-unit/components/real-estate-unit-selection-modal/real-estate-unit-selection-modal.component';
import {
  ManagerSelectionModalComponent
} from 'src/app/pages/registry/manager/components/manager-selection-modal/manager-selection-modal.component';
import { Condominium } from 'src/app/models/condominium';
import { SocietySelectionComponent } from '../../society-selection/society-selection.component';
import { piBuilding, piBuildingWhite, piRefresh, PwcIconsLibrary } from '@pwc-ecobonus/icons';
import { StarredSocietyService } from 'src/app/services/starred-society.service';
import {DownloadFilesService} from "../../../services/download/download-files.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BasePage implements OnInit {

  @Input() projectId: number;
  @Input() project: Project;
  @Input() readonly: boolean = false;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() submit: EventEmitter<Condominium> = new EventEmitter<Condominium>();
  @Output() submitAndNew: EventEmitter<Condominium> = new EventEmitter<Condominium>();

  @ViewChild('realEstateUnitSelectionModal') realEstateUnitSelectionModal: RealEstateUnitSelectionModalComponent;
  @ViewChild('societySelection') societySelection: SocietySelectionComponent;
  @ViewChild('managerSelectionModal') managerSelectionModal: ManagerSelectionModalComponent;

  condominiumTypes: CondominiumType[] = [];
  condominiumMinima: CodeNameEntity[] = [];
  realEstateUnitsCondominium: RealEstateUnitCondominium[] = [];
  realEstateUnitChanged: boolean[] = [];
  managers: CondominiumManager[] = [];
  manager: Owner;
  owner: Owner;
  managersChanged: boolean[] = [];
  tipiCatasto = ['T', 'U'];

  cfValidationMessage: string = null;
  codCatastaleValidationMessage: string = null;

  condominium: Condominium = this.newCondominium();

  private isValidMap: Map<string, boolean> = new Map();
  private validationRulesMap: Map<string, any> = new Map();

  user: User;
  userInitials: string = '';
  notificationCount: number = 0;
  societies: Society[] = [];
  selectedSociety: Society;
  starredSociety: number = null;

  manualFileName: string;
  faqFileName: string;

  AuthorityCodeEnum = AuthorityCodeEnum;

  readonly secondaryLogoLookup: Map<LogoKey, string> = new Map([
    [LogoKey.PWC, '/assets/images/logo.png'],
    [LogoKey.UNICREDIT, '/assets/images/logos/unicredit.png'],
    [LogoKey.P4E, '/assets/images/logos/p4e.png'],
    [LogoKey.FONDITAL, '/assets/images/logos/fondital.png'],
    [LogoKey.FIVESIXTY, '/assets/images/logos/five60.png'],
  ]);

  environment = environment;

  secondaryLogo: string = null;

  constructor(
    private authenticationService: AuthenticationService,
    private societiesService: SocietiesService,
    private generalSettingValueService: GeneralSettingValueService,
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private idleTimeoutService: IdleTimeoutService,
    private starredSocietyService: StarredSocietyService,
    private iconsLibrary: PwcIconsLibrary,
    private downloadService: DownloadFilesService,
    private logger: Logger) {
    super();

    this.iconsLibrary.registerIcons(
      [piBuilding, piBuildingWhite, piRefresh]
    );
  }

  ngOnInit(): void {
    this.idleTimeoutService.start();

    this.initLoggedUser();
    this.initNotificationsCount();
    this.initSocieties();
    this.initSecondaryLogo();
    this.initUserManual();
    this.initFaq();
  }

  public newCondominium(): Condominium {
    return {
      id: null,
      name: null,
      type: 'condominio_verticale',
      minimum: null,
      isMinimum: false,
      cessioneUnanime: null,
      cf: null,
      selected: false,
      realEstateUnitsCount: 0,
      society: null,
      address: {
        street: null,
        civ: null,
        internalNumber: null,
        ladder: null,
        cap: null,
        district: null,
        province: null,
        region: null,
        country: null,
      },
      datiCatastali: {
        codCatastaleComune: null,
        catasto: 'U',
        sezUrbana: null,
        foglio: null,
        particella: null,
        subalterno: null,
      },
      otherInfo: {
        addressDetail: null,
        note: null,
        destination: null,
        restriction: false
      }

    };
  }

  private initLoggedUser(): void {
    this.userInitials = '';

    this.authenticationService.dataUser()
      .subscribe((user: User) => {
        this.user = user;

        if (this.user != null) {
          if (this.user.firstName != null) {
            this.userInitials += this.user.firstName.substr(0, 1);
          }
          if (this.user.lastName != null) {
            this.userInitials += this.user.lastName.substr(0, 1);
          }
        }
      });
  }

  private showPrivacy() {
    return environment.configuration.showPrivacy && (
      (this.router.url.indexOf(RoutePath.TERMS_AND_CONDITIONS) < 0
        && this.router.url.indexOf(RoutePath.PRIVACY_POLICY) < 0
      )
    );
  }

  private initNotificationsCount(): void {
    if (environment.notificationInterval > 0) {
      setInterval(() => {
        this.http.get<number>(`${environment.authUrl}/notification/count`)
          .pipe(first())
          .subscribe((count: number) => {
            this.notificationCount = count;
          }, (error) => {
            this.logger.error(error);
          });
      }, environment.notificationInterval * 1000);
    }
  }

  private initSocieties(): void {
    this.societiesService.getUserCompany()
      .subscribe((data: Society[]) => {
        this.societies = data;

        if (this.societies.length > 0) {
          this.updateStarredSociety(() => {
            this.selectedSociety = this.getSocietyToSelect();
            this.setDefaultSociety();
          });
        }
      });
  }

  private getSocietyToSelect() {
    var societyFromQueryParams = this.getSocietyFromQueryParams();
    if (societyFromQueryParams)
      return societyFromQueryParams;
    if (this.starredSociety) {
      return this.societies.filter(s => s.id === this.starredSociety)[0];
    }
    return this.societies[0];
  }

  private getSocietyFromQueryParams(): Society {
    let paramMap: ParamMap = this.activatedRoute.snapshot.queryParamMap;
    let societyId: number = Number.parseInt(paramMap.get('societyId'));
    if (societyId)
      return this.societies.filter(s => s.id === societyId)[0];
    return null;
  }

  private initSecondaryLogo(): void {
    this.societiesService.getSelectedSociety()
      .pipe(
        switchMap((society: Society) => this.generalSettingValueService.findByCode('SECONDARY_LOGO', society.id))
      )
      .subscribe((list: GeneralSettingValue[]) => {
        if (list == null || list.length === 0) {
          this.secondaryLogo = null;
          return;
        }

        const setting = list[0];
        if (this.secondaryLogoLookup.has(setting.value as LogoKey)) {
          this.secondaryLogo = this.secondaryLogoLookup.get(setting.value as LogoKey);
        } else {
          this.secondaryLogo = null;
        }
      });
  }

  private initUserManual(): void {
    this.societiesService.getSelectedSociety()
      .pipe(
        switchMap((society: Society) => this.generalSettingValueService.findByCode('ECOBONUS_USER_MANUAL_B2C', society.id))
      )
      .subscribe((list: GeneralSettingValue[]) => {
        if (list == null || list.length === 0) {
          this.manualFileName = environment.configuration.manualFileName;
          return;
        }

        const setting = list[0];
        this.manualFileName = setting.value;
      });
  }

  private initFaq(): void {
    this.societiesService.getSelectedSociety()
      .pipe(
        switchMap((society: Society) => this.generalSettingValueService.findByCode('ECOBONUS_FAQ_FILENAME', society.id))
      )
      .subscribe((list: GeneralSettingValue[]) => {
        if (list == null || list.length === 0) {
          this.faqFileName = environment.configuration.faqFileName;
          return;
        }

        const setting = list[0];
        this.faqFileName = setting.value;
      });
  }

  logout(): void {
    this.authenticationService.logout();
  }

  navigateToTermsAndConditions(): void {
    this.router.navigate([RoutePath.TERMS_AND_CONDITIONS]);
  }

  navigateToPrivacyPolicy(): void {
    this.router.navigate([RoutePath.PRIVACY_POLICY]);
  }

  navigateToChangePassword(): void {
    window.location.href = `${environment.frameworkUrl}/account/change-password`;
  }

  navigateToFaq(): void {
    this.societiesService.getSelectedSociety().subscribe(
      (society: Society) => {
        var queryParams: string = `societyId=${society.id}`;
        const url = this.router.serializeUrl(
          this.router.createUrlTree([
            RoutePath.FAQ_LIST
          ])
        ) + '?' + queryParams;
        window.open(url, '_blank');
      }
    )

  }

  navigateToMySupportRequests(): void {
    this.router.navigate([
      RoutePath.SUPPORT_REQUEST,
      RoutePath.MY_LIST
    ])
  }

  setCurrentSociety(event: Society): void {
    this.selectedSociety = event;
    this.societiesService.setSociety(event);
    this.authorizationService.getAuthorities(true).subscribe();
  }

  setDefaultSociety(): void {
    this.societiesService.setSociety(this.selectedSociety);
    this.authorizationService.getAuthorities(true).subscribe();
  }

  openModal(): void{
    this.societySelection.open();
  }

  updateStarredSociety(callback?): void {
    this.starredSocietyService.getStarredSociety().subscribe((society: Society) => {
      this.starredSociety = society.id;
      if (callback) {
        callback();
      }
    });
  }

  download(path: string, filename: string) {
    this.societiesService.getSelectedSociety()
      .subscribe( (society: Society) => {
          this.downloadService.download(society.id, path, filename);
        }
      )
  }
}
