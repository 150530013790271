// .typing-indicator {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
//   width: 100%;
//   padding: 0 1rem;
//   font-size: 1.2rem;
//   font-weight: 500;
//   color: #fff;
//   background-color: #f5f5f5;
//   border-radius: 0.5rem;
//   box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
//   animation: typing 1.5s steps(5, end) infinite;
// }
//
// @keyframes typing {
//   from {
//     width: 0;
//   }
//   to {
//     width: 100%;
//   }
// }

.typing-spinner {
  //   border: 3px solid rgba(0, 0, 0, 0.1);
  //   border-top-color: #3498db;
  //   border-radius: 50%;
  //   width: 20px;
  //   height: 20px;
  animation: breathe 1s linear infinite, spin 1s linear infinite;
}

@keyframes breathe {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }

}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

