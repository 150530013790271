import {Injectable, SecurityContext} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {DomSanitizer} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class DownloadFilesService {
  protected baseUrl: string = environment.apiUrl;
  protected endpoint: string = 'download/resource';

  constructor(private http: HttpClient,
              private domSanitizer: DomSanitizer) { }

  download(societyId:number, path:string, fileName?:string) {
    path = path.replace("/assets/downloads/", "");
    return this.http.get<any>(
      `${this.baseUrl}/${this.endpoint}/b2b?societyId=${societyId}&path=${path}`,
      {responseType: 'blob' as 'json'}
    )
      .subscribe((data: any) => {
        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = this.domSanitizer.sanitize(SecurityContext.NONE, downloadURL);
        if (fileName) {
          link.download = fileName;
        }
        link.target = '_blank';

        link.click();

        document.body.removeChild(link);
      });
  }
}
