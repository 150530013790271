.message {
  display: flex;
  width: 380px !important;
  font-size: 12px !important;
}

.bot-message {
  flex-direction: row;
  text-align: left;
  // margin-right: 5%;
  animation-name: slide-from-left;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

.user-message {
  flex-direction: row-reverse;
  text-align: left;
  min-width: auto;
  color: white !important;
  // max-width: auto;
  //  margin-left: 5%;
  animation-name: slide-from-right;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

@keyframes slide-from-left {
  from {
    margin-left: -200px
  }
  to {
    margin-left: 0
  }
}

@keyframes slide-from-right {
  from {
    margin-right: -200px
  }
  to {
    margin-right: 0
  }
}

.message-text {
  margin: 0 3% 3% 3%;
  padding: 10px;
  border: transparent;
  border-radius: 25px;
  max-width: fit-content;
}

.bot-message-text {
  background-color: #eeeeee;
  color: #808080;
  border-top-left-radius: 0 !important;
}

.user-message-text {
  background-color: #d04a02;
  color: white;
  border-top-right-radius: 0 !important;
  text-align: left !important;
}

// From app header component
.balloon {
  white-space: nowrap;
  line-height: 18px;
  vertical-align: middle;
  background-color: #D8D8D8;
  color: #000000;

  &.trasparent {
    background-color: transparent;
  }
}
